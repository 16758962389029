<template>
  <div>
    <SuspenseLoader :isLoaded="isLoaded">
      <BatteryChartBlock v-if="isLoaded" :data="powerLevels" />
    </SuspenseLoader>
  </div>
</template>

<script>
import { VehicleConfig } from '@/config/VehicleConfig'
export default {
  name: 'ViewVehicleBattery',
  components: {
    BatteryChartBlock: () =>
      import('@/composites/vehicle/battery/BatteryChartBlock'),
    SuspenseLoader: () => import('@/components/loader/SuspenseLoader.vue'),
  },
  data() {
    return {
      isLoaded: false,
      isLoading: false,
      err: null,
      powerLevels: [],
    }
  },
  computed: {
    id() {
      return this.$route.params.id
    },
  },
  async created() {
    let startDate = this.$dayjs().format('YYYY-MM-DD')
    // let endDate = this.$dayjs().format('YYYY-MM-DD')
    await this.fetchData(startDate)
  },
  methods: {
    // fetch, set & return data
    async fetchData(startDate = '2021-02-24') {
      const endpoint = VehicleConfig.api.powerLevel(this.id, startDate)
      this.isLoading = true

      await this.$http
        .get(endpoint)
        .then((res) => {
          // console.warn(res.data.data);

          this.isLoaded = true
          // serilize for the chart
          let arr = res.data.data.map((item) => ({
            x: new Date(item.created_at),
            y: Number(item.vehicle_power_level).toFixed(),
          }))

          // Remove duplicates of multidimensional array
          // https://gist.github.com/juliovedovatto/f4ac657e5d28e060c791f5ef27b13341
          this.powerLevels = arr
            .map(JSON.stringify)
            .reverse() // convert to JSON string the array content, then reverse it (to check from end to begining)
            .filter(function(item, index, arr) {
              return arr.indexOf(item, index + 1) === -1
            }) // check if there is any occurence of the item in whole array
            .reverse()
            .map(JSON.parse) // revert it to original state

          // console.warn({ p: this.powerLevels });
          return this.powerLevels
        })
        .catch((err) => {
          console.warn(err)

          this.err = err
          return err
        })

      this.isLoading = false
    },
  },
}
</script>

<style lang="scss"></style>
